<div class="row no-gutters table-check-element">
  <div class="table-check-element__item table-check-element__item--expanded produced-material">
    <p-table [value]="traceabilityItem" [columns]="columns">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let column of columns">
            <th>{{ column.label | translate }}</th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="table-text">
            {{ rowData.productionDate }}
          </td>
          <td class="table-text">
            {{ rowData.containerIdentification }}
          </td>
          <td class="table-text">
            {{ rowData.producedMaterialSequenceNumber }}
          </td>
          <td class="table-text">
            {{ rowData.bomGoodQuantity.value }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseId }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseLocationId }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-template #noData>
      <span>-</span>
    </ng-template>
  </div>
</div>
