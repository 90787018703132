import { Injectable } from '@angular/core';
import { TraceabilitySearchModeOption } from '../models/traceability.model';

export interface TableHeader {
  label: string;
  key: string;
  width: string;
  type: string;
  styleClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableHeaderService {
  getParentTableHeaders(selectedTracebility: string): TableHeader[] {
    if (selectedTracebility === TraceabilitySearchModeOption.ForwardSearch) {
      return this.getForwardSearchParentHeaders();
    } else {
      return this.getBackwardSearchParentHeaders();
    }
  }

  getChildTableHeaders(selectedTracebility: string): TableHeader[] {
    if (selectedTracebility === TraceabilitySearchModeOption.ForwardSearch) {
      return this.getForwardSearchChildHeaders();
    } else {
      return this.getBackwardSearchChildHeaders();
    }
  }

  private getForwardSearchParentHeaders(): TableHeader[] {
    return [
      { label: 'TRACEABILITY.FORWARD_SEARCH_EXT_PROD_ORDER_ID', key: 'orderId', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_FINISH_GOOD', key: 'FG', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: '', key: 'code', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_DEVIATION', key: 'deviation', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_WC', key: 'wc', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_STATUS', key: 'status', width: '10%', type: 'string', styleClass: 'tiny-cell' }
    ];
  }

  private getBackwardSearchParentHeaders(): TableHeader[] {
    return [
      { label: 'TRACEABILITY.BACKWARD_SEARCH_PRODUCTION_ORDER', key: 'orer', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_ARTICLE', key: 'article', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_NAME', key: 'name', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_ITEMS', key: 'item', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_DEVIATION', key: 'eviation', width: '10%', type: 'string', styleClass: 'tiny-cell' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_WORKCENTER', key: 'wc', width: '10%', type: 'string', styleClass: 'tiny-cell' }
    ];
  }

  public getForwardSearchChildHeaders(): TableHeader[] {
    return [
      { label: 'TRACEABILITY.FORWARD_SEARCH_PROD_DATE', key: 'productionDate', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_SSCC', key: 'sscc', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_PALLET_NR', key: 'palletNr', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_QUANTITY', key: 'quantity', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_WAREHOUSE', key: 'warehouse', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.FORWARD_SEARCH_LOCATION', key: 'location', width: '10%', type: 'string' }
    ];
  }

  public getBackwardSearchChildHeaders(): TableHeader[] {
    return [
      { label: 'TRACEABILITY.BACKWARD_SEARCH_DATE_CONSUMPTION', key: 'consumptionDate', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_SSCC', key: 'sscc', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_CONSUMED', key: 'consumed', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_WHITE_WASTE', key: 'whiteWaste', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_WAREHOUSE', key: 'warehouse', width: '10%', type: 'string' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_LOCATION', key: 'location', width: '10%', type: 'string' }
    ];
  }
}
