import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BackwardTraceContainer } from 'chronos-core-client';
import { PipesModule } from '../../../../pipes/pipes.module';
@Component({
  selector: 'lib-consumption-batch-list',
  templateUrl: './consumption-batch-list.component.html',
  styleUrls: ['./consumption-batch-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsumptionbatchlistComponent implements OnInit {
  public isCollapsed = true;

  @Input() public orderBatchItem: BackwardTraceContainer;

  constructor(private utcToLocal: PipesModule) {}

  public columns = [];

  public ngOnInit(): void {
    this.initColumns();
  }

  private initColumns(): void {
    this.columns = [
      { label: 'TRACEABILITY.BACKWARD_SEARCH_DATE_CONSUMPTION' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_SSCC' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_CONSUMED' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_WHITE_WASTE' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_WAREHOUSE' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_LOCATION' }
    ];
  }
}
