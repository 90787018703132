import { finalize } from 'rxjs/operators';
import { BackwardTraceBomLine, ForwardTraceOrder } from 'chronos-core-client';
import { Component, OnInit } from '@angular/core';
import moment from 'moment-mini';
import { SelectItem } from 'primeng/api';
import { TraceabilitySearchModeOption } from '../../models/traceability.model';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TraceabilityMainService } from '../../services/traceability-main.service';

@Component({
  selector: 'lib-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.scss']
})
export class TraceabilityComponent implements OnInit {
  public traceabilitySearchModeOption: SelectItem[];
  public selectedSearchMode: SelectItem;
  public setupStartTime: Date;
  public setupEndTime: Date;
  public textQuery? = '';
  public searchPlaceHolder = '';
  public maxDate: Date;
  public isNotLoading = true;
  public isNodeLoadingSubject = new BehaviorSubject<boolean>(false);
  public isNodeLoading$ = this.isNodeLoadingSubject.asObservable();
  public searchCode: string;
  public searchPeriod: string;
  public forwardSearchData: ForwardTraceOrder[];
  public backwardSearchData: BackwardTraceBomLine[];
  public showTables = false;
  isSubmitDisabled = true;
  isExportDisabled = true;

  constructor(private translateService: TranslateService, private traceabilityMainService: TraceabilityMainService) {}

  public ngOnInit(): void {
    this.traceabilitySearchModeOption = [
      { label: this.translateService.instant('TRACEABILITY.FORWARD_SEARCH'), value: TraceabilitySearchModeOption.ForwardSearch },
      { label: this.translateService.instant('TRACEABILITY.BACKWARD_SEARCH'), value: TraceabilitySearchModeOption.BackwardSearch }
    ];
    this.searchPlaceHolder = 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER';
    this.setupStartTime = moment(new Date()).subtract(1, 'month').toDate();
    this.setupEndTime = this.maxDate = new Date();
    this.selectedSearchMode = this.traceabilitySearchModeOption[0].value;
  }

  public onFilterChange(): void {
    this.searchPlaceHolder =
      this.selectedSearchMode === this.traceabilitySearchModeOption[0].value
        ? 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER'
        : 'TRACEABILITY.BACKWARD_SEARCH_PLACEHOLDER';

    if (this.textQuery && this.textQuery.length > 0) {
      this.isSubmitDisabled = false;
    } else {
      this.isSubmitDisabled = true;
    }
  }

  public onStartDateChange(): void {
    this.setupStartTime = this.setupStartTime > this.setupEndTime ? this.setupEndTime : this.setupStartTime;
  }

  public onEndDateChange(): void {
    this.setupEndTime =
      this.setupStartTime < this.setupEndTime ? (this.setupEndTime > this.maxDate ? this.maxDate : this.setupEndTime) : this.setupStartTime;
  }

  public submitFilters(): void {
    this.isNotLoading = false;
    this.showTables = false;
    if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
      this.traceabilityMainService
        .getTraceabilityForwardSearch(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe(
          (data) => {
            if (data && data.length > 0) {
              this.forwardSearchData = data;
              this.showTables = true;
              this.isExportDisabled = false;
            } else {
              this.isExportDisabled = true;
            }
            this.isNotLoading = false;
          },
          (error) => {
            this.isNotLoading = false;
            console.info(error);
          }
        );
    } else {
      this.traceabilityMainService
        .getTraceabilityBackwardSearch(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe(
          (data) => {
            if (data && data.length > 0) {
              this.backwardSearchData = data;
              this.showTables = true;
              this.isExportDisabled = false;
            } else {
              this.isExportDisabled = true;
            }
            this.isNotLoading = false;
          },
          (error) => {
            this.isNotLoading = false;
            console.info(error);
          }
        );
    }
  }

  public export(): void {
    this.isNotLoading = true;
    if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
      this.traceabilityMainService
        .getTraceabilityForwardSearchExport(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe((data) => {
          if (data) {
            this.downloadCSV(data.csvContent);
            this.isNotLoading = false;
          }
        });
    } else {
      this.traceabilityMainService
        .getTraceabilityBackwardSearchExport(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe((data) => {
          if (data) {
            this.downloadCSV(data.csvContent);
            this.isNotLoading = false;
          }
        });
    }
  }

  private downloadCSV(csvData: string) {
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.selectedSearchMode}_export_data.csv`;
      link.click();
    }
  }
}
