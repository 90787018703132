<!-- ============================================================== -->
<!-- Topbar - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-toolbar color="primary" class="topbar telative">
  <!-- ============================================================== -->
  <!-- sidebar toggle -->
  <!-- ============================================================== -->
  <button mat-icon-button (click)="toggleSidenav()" value="sidebarclosed">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Logo - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="navbar-header">
    <a class="navbar-brand" href="/">
      <span fxShow="false" fxShow.gt-xs>
        <div class="dynamic-logo">
          <img [src]="applicationLogo" />
        </div>
      </span>
    </a>
  </div>

  <div class="app-title">
    <div fxHide.xs class="app-title-gt-xs">
      {{ desktopAppTitle }}
    </div>
    <div fxHide.gt-xs class="app-title-xs">
      {{ mobileAppTitle }}
    </div>
  </div>

  <span fxFlex></span>
  <!-- ============================================================== -->
  <!-- app header component - style you can find in header.scss / header.component.ts-->
  <!-- ============================================================== -->

  <div class="text-right" style="font-size: 12px !important; color: gray; max-height: 20px; min-height: 20px" fxFlex="82px">
    <lib-version-number [version]="version"></lib-version-number>
  </div>
  <!-- <div class="mat-button text-right p-l-0" fxFlex="150px" fxHide.lt-md="true">
     <app-organization-filter></app-organization-filter> 
  </div> -->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
  <!-- <button [matMenuTriggerFor]="message" mat-icon-button>
    <mat-icon>email</mat-icon>
    <div class="notify">
      <span class="heartbit"></span>
      <span class="point"></span>
    </div>
  </button> -->
  <mat-menu #message="matMenu" class="mymessage">
    <div class="mailbox">
      <ul>
        <!-- <li>
          <div class="drop-title">You have {{notifications.length}} new notifications</div>
        </li> -->
        <li>
          <div class="message-center">
            <!-- Message -->
            <a href="#" *ngFor="let notif of notifications">
              <div class="user-img">
                <img src="{{ notif.userImage }}" alt="user" class="img-circle" width="40" />
                <span class="profile-status {{ notif.status }} pull-right"></span>
              </div>
              <div class="mail-content">
                <h5>{{ notif.userName }}</h5>
                <span class="mail-desc">{{ notif.title }}</span>
                <span class="time">{{ notif.createdAt }}</span>
              </div>
            </a>
            <!-- Message -->
          </div>
        </li>
      </ul>
    </div>
  </mat-menu>

  <button [matMenuTriggerFor]="profile" mat-button>
    <img fxShow fxHide.lt-sm src="assets/images/users/no-image.png" alt="user" class="profile-pic m-r-5" />
    <span>{{ userDisplayName }}</span>
  </button>

  <mat-menu #profile="matMenu" class="mymegamenu">
    <mat-list-item *ngFor="let menuItem of profileMenuItems.getMenuItem()" routerLinkActive="selected">
      <button mat-menu-item [routerLink]="['/', menuItem.state]">
        <mat-icon>{{ menuItem.icon }}</mat-icon>
        {{ translationKeyPrefix + 'MenuItem.' + menuItem.name.replace(' ', '') | translate }}
      </button>
    </mat-list-item>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>
      {{ translationKeyPrefix + 'SignOut' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>
