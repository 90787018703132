/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { OrganizationsCachedService } from './organizations.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [OrganizationsCachedService]
})
export class CachedLiveApiModule {}
