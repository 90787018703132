<div class="row no-gutters table-check-element">
  <div class="table-check-element__item table-check-element__item--expanded consumption-batch">
    <p-table [value]="orderBatchItem" [columns]="columns">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let column of columns">
            <th>{{ column.label | translate }}</th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="table-text">
            {{ rowData.consumptionDate | utcToLocal | date : 'dd.MM.yyyy' }}
          </td>
          <td class="table-text">
            {{ rowData.containerIdentification }}
          </td>
          <td class="table-text">{{ rowData.consumedQuantity?.value }}&nbsp;{{ rowData.consumedQuantity?.unitId }}</td>
          <td class="table-text">{{ rowData.whiteWaste?.value }}&nbsp;{{ rowData.whiteWaste?.unitId }}</td>
          <td class="table-text">
            {{ rowData.externalWarehouseId }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseLocationId }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template #noData>
      <span>-</span>
    </ng-template>
  </div>
</div>
