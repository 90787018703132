<ng-container>
  <div class="table-parameters__table">
    <div class="table-parameters__body main-table" *ngIf="isForwadSearchMode">
      <p-table [value]="forwardSearchData" [columns]="columns" dataKey="productionOrderId" [responsive]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 2.25em"></th>
            <ng-container *ngFor="let column of columns">
              <th>{{ column.label | translate }}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
          <tr>
            <td>
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" class="expand-collapse-icon"></i>
              </a>
            </td>
            <td class="table-text">
              {{ rowData.externalProductionOrderId }}
            </td>
            <td class="table-text">
              {{ rowData.articleName }}
            </td>
            <td class="table-text">{{ rowData.externalArticleId }} / {{ rowData.configurationId }}</td>
            <td class="table-text">
              {{ rowData.deviationPercentage }}
            </td>
            <td class="table-text">
              {{ rowData.externalWorkCenterId }}
            </td>
            <td class="table-text">{{ rowData.status }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length + 1" class="first-level">
              <lib-production-material-list
                [selectedSearchMode]="selectedSearchMode"
                [traceabilityItem]="rowData.forwardTraceContainers"
              ></lib-production-material-list>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <br />
    </div>
    <div class="table-parameters__body main-table" *ngIf="!isForwadSearchMode">
      <p-table [value]="backwardSearchData" [columns]="columns" dataKey="externalProductionOrderId" [responsive]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 2.25em"></th>
            <ng-container *ngFor="let column of columns">
              <th>{{ column.label | translate }}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
          <tr>
            <td>
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" class="expand-collapse-icon"></i>
              </a>
            </td>
            <td class="table-text">{{ rowData.articleName }}</td>
            <td class="table-text">{{ rowData.configurationName }}</td>
            <td class="table-text">{{ rowData.configurationName }}</td>
            <td class="table-text">{{ rowData.itemCount }}</td>
            <td class="table-text">{{ rowData.deviationPercentage }}</td>
            <td class="table-text">{{ rowData.externalWorkCenterId }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length + 1" class="first-level">
              <lib-order-batch-list [traceabilityItem]="rowData.mountedBatches"></lib-order-batch-list>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
