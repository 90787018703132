import { Component, OnInit, Input } from '@angular/core';
import { Widget } from '../../dashboard/decorators/widget.decorator';
import { WidgetLayout } from '../../dashboard/models/widget-layout';
import { ChartType } from 'chronos-shared';

@Widget('MachineChart')
@Component({
  selector: 'app-machine-chart-widget',
  templateUrl: './machine-chart-widget.component.html',
  styleUrls: ['./machine-chart-widget.component.scss']
})
export class MachineChartWidgetComponent implements OnInit {
  @Input() public basicSettings: any;
  @Input() public customSettings: any;
  public widgetConfig: any;
  public widgetLayout: WidgetLayout;
  public workCenterId: number;
  public chartMode: string;
  constructor() {}
  public ngOnInit(): void {
    this.chartMode = ChartType.fullView;
    if (this.customSettings) {
      this.workCenterId = this.customSettings.find((x: { key: string }) => x.key.toLowerCase() === 'workcenterid').value;
    }
  }
}
