import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BackwardTraceMountedBatch } from 'chronos-core-client';

@Component({
  selector: 'lib-order-batch-list',
  templateUrl: './order-batch-list.component.html',
  styleUrls: ['./order-batch-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderbatchlistComponent implements OnInit {
  public isCollapsed = true;
  public columns = [];

  @Input() public traceabilityItem: BackwardTraceMountedBatch | BackwardTraceMountedBatch;
  constructor() {}

  public ngOnInit(): void {
    this.initColumns();
  }

  private initColumns(): void {
    this.columns = [
      { label: 'TRACEABILITY.BACKWARD_SEARCH_INTERNAL_BATCHID' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_EXTERNAL_BATCHID' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_DATE_CONSUMPTION' },
      { label: 'TRACEABILITY.BACKWARD_SEARCH_ITEMS' }
    ];
  }
}
