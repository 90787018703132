<div class="table-parameters__body pad-lr">
  <div class="qa-parameters__element row no-gutters">
    <div class="col">
      <div class="table-check-element__item table-check-element__item--expanded order-batch">
        <p-table [value]="traceabilityItem" [columns]="columns" dataKey="internalBatchId" [responsive]="true">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 2.25em"></th>
              <ng-container *ngFor="let column of columns">
                <th>{{ column.label | translate }}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded">
            <tr>
              <td>
                <a href="#" [pRowToggler]="rowData">
                  <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" class="expand-collapse-icon"></i>
                </a>
              </td>
              <td class="table-text">
                {{ rowData.internalBatchId | utcToLocal | date : 'dd.MM.yyyy' }}
              </td>
              <td class="table-text">
                {{ rowData.externalBatchId }}
              </td>
              <td class="table-text">
                {{ rowData.consumptionDate | utcToLocal | date : 'dd.MM.yyyy' }}
              </td>

              <td class="table-text">
                {{ rowData.itemCount }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
            <tr>
              <td [attr.colspan]="columns.length + 1" class="second-level">
                <lib-consumption-batch-list [orderBatchItem]="rowData.containers"></lib-consumption-batch-list>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
