import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TraceabilitySearchModeOption } from '../../models/traceability.model';
import { BackwardTraceBomLine, ForwardTraceOrder } from 'chronos-core-client';
import { TableHeaderService, TableHeader } from '../../services/table-header.service';
@Component({
  selector: 'lib-production-order-list',
  templateUrl: './production-order-list.component.html',
  styleUrls: ['./production-order-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductionorderlistComponent implements OnInit {
  @Input() public selectedSearchMode: string;
  public columns: TableHeader[];
  @Input() public forwardSearchData: ForwardTraceOrder[];
  @Input() public backwardSearchData: BackwardTraceBomLine[];
  public isForwadSearchMode = true;

  constructor(private tableHeaderService: TableHeaderService) {}

  public ngOnInit(): void {
    this.columns = this.tableHeaderService.getParentTableHeaders(this.selectedSearchMode);
    this.isForwadSearchMode = this.selectedSearchMode === TraceabilitySearchModeOption.ForwardSearch ? true : false;
  }
}
