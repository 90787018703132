import { map } from 'rxjs/operators';
import { AuthenticationService } from './../services/custom/authentication.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'chronos-live-client';
import { applicationPaths } from '../services/custom/application-path.constant';
import { globalVariable } from '../services/custom/authentication.constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService, private navigationService: NavigationService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state && state.url && (state.url.indexOf('speedmonitor') > -1 || state.url.indexOf('machinechart') > -1)) {
      return true;
    }
    const currentUser = this.authenticationService.currentUserValue;
    const relativePath = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
    if (currentUser) {
      sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(currentUser));
      this.authenticationService.currentUserSubject.next(currentUser);
      return this.navigationService.validateNavigation(relativePath).pipe(
        map((isValid) => {
          if (isValid) {
            return true;
          } else {
            this.router.navigate([applicationPaths.ActiveDirDenied], {
              queryParams: { returnUrl: state.url }
            });
            return false;
          }
        })
      );
    } else {
      this.authenticationService.logout();
      this.router.navigate([applicationPaths.ActiveDirLogin], {
        queryParams: { returnUrl: state.url }
      });
      return false;
    }
  }
}
