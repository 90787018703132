import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helpers/auth.gaurd';
import { ProfileComponent } from './layouts/full/profile/profile.component';
import { SpeedMonitorComponent, MachineChartComponent } from 'chronos-shared';

export const appRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'configuration',
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'hierarchy-browser',
        loadChildren: () => import('./modules/hierarchy-browser/hierarchy-browser.module').then((m) => m.HierarchyBrowserModule)
      },
      {
        path: 'hierarchy-management',
        loadChildren: () => import('./modules/hierarchy-management/hierarchy-management.module').then((m) => m.HierarchyManagementModule)
      },
      {
        path: 'traceability',
        loadChildren: () => import('./modules/traceability/traceability-main.module').then((m) => m.TraceabilityMainModule)
      },
      {
        path: 'demo',
        loadChildren: () => import('./modules/demo/demo.module').then((m) => m.DemoModule)
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'machinechart',
        children: [
          {
            path: '**',
            component: MachineChartComponent
          }
        ]
      },
      {
        path: 'speedmonitor/:externalWorkCenterId',
        component: SpeedMonitorComponent
      }
    ]
  },
  {
    path: 'auth',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'auth/ad-login'
  }
];
