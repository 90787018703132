import { NavigationEnum } from './../../../modules/dashboard/widget/decorators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, Menu } from '../../../shared/menu-items/menu-items';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/custom/authentication.service';
import { NavigationService } from 'chronos-live-client';
import { UxpageUp } from 'chronos-live-client';
import { MatDialog } from '@angular/material/dialog';
import { CustomSettingDialogComponent } from '../../../notifications/customsetting-dialog/customsetting-dialog.component';
import { ILocalization } from '../../../services/custom/interface/ILocalization.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy, ILocalization {
  public mobileQuery: MediaQueryList;
  public itemSelect: number[] = [];
  public sideBarMenu: Menu[] = [];

  private _mobileQueryListener: () => void;
  private activeSubscription: Subscription[] = [];
  public translationKeyPrefix: string;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    public dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    // eslint-disable-next-line import/no-deprecated
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.translationKeyPrefix = 'Sidebar.MenuItem.';
  }

  public ngOnInit() {
    const currentSubjectSubscription = this.authenticationService.currentUserSubject.subscribe(() => {
      this.getSideNavigation();
    });

    this.activeSubscription.push(currentSubjectSubscription);
  }

  public ngOnDestroy(): void {
    // eslint-disable-next-line import/no-deprecated
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.activeSubscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public getSideNavigation() {
    this.navigationService.getNavigation().subscribe({
      next: (navigationList) => {
        this.sideBarMenu = [];
        navigationList.sort((a, b) => a.uporder - b.uporder);

        navigationList.forEach((item: UxpageUp) => {
          const isShowMenu = window.innerWidth <= 576 && item.upname === NavigationEnum.configuration ? false : true;

          if (!item.upparent) {
            const menuItem: Menu = {
              state: item.uxurl,
              name: item.upname,
              icon: item.upicon,
              type: 'link',
              isVisible: isShowMenu
            };
            this.sideBarMenu.push(menuItem);
          }
        });
      }
    });
  }

  public onResize() {
    const menu = this.sideBarMenu.find((item) => item.name === 'Configuration');
    if (menu) {
      menu.isVisible = window.innerWidth > 576;
    }
  }

  public openCustomeSettings() {
    const title = 'Custom Settings';
    this.dialog.open(CustomSettingDialogComponent, {
      width: '600px',
      data: { dialogTitle: title }
    });
  }
}
